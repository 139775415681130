export const environment = {
  production: true,
  staging: false
};
export const APP_KEY = 'XXXXXX';
// 環境ごとに調整
export const API_SERVER_URL = 'https://api-frt.qoala.jp/';
export const SERVICE_URL = 'https://secure.qoala.jp/';
export const GA_TRACK_ID = 'UA-112028206-2';
export const FULLSTAR_EMBED_ID = 'd0108bef-bc8d-4277-ad9e-7cc895c604a0';
export const FRONT_DOMAIN = 'qoala.jp'; // v1とv2のcookieを共有するため同じドメイン想定
export const V2_FRONT_URL = 'https://app.qoala.jp/';
